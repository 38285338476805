import React from 'react';
import logo from './images/scvbyte_logo.png';

function App() {
  return (
    <div className="App">
      <div class="flex justify-center items-center">
      <img src={logo} className="App-logo" alt="logo" />
      </div>
      <header className="App-header">
       
        <p>
        <div class="mx-auto max-w-2xl mt-16 text-center">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          
          </h1>
          <h1 class="text-4xl font-bold tracking-tight text-blue-700 sm:text-6xl">
            The byte strike...
          </h1>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            Introducing our new platform to serve you better. Stay tuned.
          </p>
          
        </div>
        </p>
  
      </header>
    </div>
  );
}

export default App;
